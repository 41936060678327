import { Rating } from '@/entities/Activity/ui/Rating';
import { numberFormatPrice, numberWithCommaFormatter } from '@/common/app/utils/numberUtils';
import URL from '@/common/app/utils/urlsUtils';
import { Gradient } from '@/screens/CommonPage/ui/commonPage/CardItem/cardItem/CardImage/CardImage.styles';
import { memo } from 'react';
import * as S from './FeaturedActivitiesPreview.styles';
import { AnalyticsInstance } from '@/common/service/analytics';
import { useInView } from 'react-intersection-observer';
import { impressionInterceptor } from '@/shared/Analytics/GA4/ImpressionInterceptor';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { IRecommendsItem } from './FeaturedActivitiesPreview.types';
import { IReview } from '@/entities/Activity/domain/Review.domain';
import emptyImg from '@/shared/images/Images/EmptyImg.png';
import Link from 'next/link';
import {
    convertRecommendedDataToAnalyticsData,
    createListNameFromPath,
    wraitDetasilsData,
} from '@/shared/Analytics/GA4/helpers';
import { promotionInterceptor } from '@/shared/Analytics/GA4/promotionInterceptor';
import { trackSelectItem, trackSelectPromotion } from '@/shared/Analytics/GA4';

export const FeaturedActivitiesPreviewsContainer = (
    props:
        | (IActivity | IRecommendsItem) & {
              position: number;
              isFeaturedList: boolean;
              listName: string;
              reviews_cnt?: string;
              rate_count?: string;
              eager?: boolean;
              reviews?: IReview[];
              isFromSearch?: boolean;
              item_list_name: string;
              item_list_id: string;
              promotion_name?: string;
          }
) => {
    const {
        duration,
        price,
        slug,
        url,
        id,
        rating_stars,
        name,
        price_strike_out,
        reviews_cnt,
        rate_count,
        eager,
        position,
        reviews,
        isFeaturedList,
        item_list_name,
        item_list_id,
        promotion_name,
    } = props;

    const reviewsCount = Number((reviews_cnt || rate_count) ?? '0');
    const reviewsCountWithComma = numberWithCommaFormatter(reviewsCount);
    const priceWithComma = numberFormatPrice(price);
    const priceStrikeOutWithComma =
        price_strike_out && price_strike_out > price ? numberFormatPrice(price_strike_out) : '';

    const [ref] = useInView({
        threshold: 1,
        delay: 100,
        trackVisibility: true,
        onChange: (inView) => {
            // eslint-disable-next-line sonarjs/no-collapsible-if
            if (inView) {
                const categoryName = (props as IRecommendsItem).analytics_data
                    ? (props as IRecommendsItem).analytics_data?.category
                    : (props as IActivity).activity_categories?.[0].name;

                const listNameGA3 = createListNameFromPath();

                if (isFeaturedList) {
                    promotionInterceptor.addPromotion(
                        convertRecommendedDataToAnalyticsData({
                            id,
                            name,
                            analytics_data: (props as IRecommendsItem).analytics_data,
                            google: props.google,
                            price,
                            price_strike_out,
                            position,
                            item_list_name,
                            item_list_id,
                            isFeatured: isFeaturedList,
                        }),
                        promotion_name
                    );
                } else {
                    impressionInterceptor.addImpression(
                        AnalyticsInstance.creators.createImpressionFromArticlePreview({
                            listName: listNameGA3,
                            categoryName: categoryName || '',
                            position: position + 1,
                        })(props),
                        true,
                        convertRecommendedDataToAnalyticsData({
                            id,
                            name,
                            analytics_data: (props as IRecommendsItem).analytics_data,
                            google: props.google,
                            price,
                            price_strike_out,
                            position,
                            item_list_name,
                            item_list_id,
                            isFeatured: isFeaturedList,
                        })
                    );
                }
            }
        },
    });

    const clickHandler = () => {
        if (isFeaturedList) {
            wraitDetasilsData({
                index: position + 1,
                item_list_name,
                item_list_id,
                promotion_name: promotion_name || '',
                item_category3: createListNameFromPath(),
            });
            trackSelectPromotion({
                creative_name: createListNameFromPath(),
                promotion_name: promotion_name || '',
                items: [
                    {
                        ...convertRecommendedDataToAnalyticsData({
                            id,
                            name,
                            analytics_data: (props as IRecommendsItem).analytics_data,
                            google: props.google,
                            price,
                            price_strike_out,
                            position,
                            item_list_name,
                            item_list_id,
                            isFeatured: isFeaturedList,
                        }),
                    },
                ],
            });
        } else {
            wraitDetasilsData({
                index: position + 1,
                item_list_name,
                item_list_id,
            });
            trackSelectItem({
                item_list_name,
                item_list_id,
                items: [
                    {
                        ...convertRecommendedDataToAnalyticsData({
                            id,
                            name,
                            analytics_data: (props as IRecommendsItem).analytics_data,
                            google: props.google,
                            price,
                            price_strike_out,
                            position,
                            item_list_name,
                            item_list_id,
                            isFeatured: isFeaturedList,
                        }),
                    },
                ],
            });
        }
    };

    return (
        <Link href={url} passHref prefetch={false}>
            <S.CardWidth ref={ref} className="card" onClick={clickHandler}>
                <S.PreviewWrapper numberOfItems={props.numberOfItems}>
                    <S.ImageContainer>
                        <S.ImgWrap>
                            <Gradient />
                            <S.Image
                                src={`${URL.image}/activity/${id}/600x400/${slug}.jpg`}
                                alt={slug}
                                loading={eager ? 'eager' : 'lazy'}
                                width="224"
                                height="187"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = `${emptyImg.src}`;
                                }}
                            />
                        </S.ImgWrap>
                        <S.PriceWrapper>
                            <>
                                {!!priceStrikeOutWithComma && (
                                    <S.StrikeOut>{priceStrikeOutWithComma}</S.StrikeOut>
                                )}
                                &nbsp;
                                <S.Price>{priceWithComma}</S.Price>
                            </>
                        </S.PriceWrapper>
                        {/* {price_strike_out && <S.NewToTripshock>Save now!</S.NewToTripshock>}
                        {!reviewsCount && !price_strike_out && (
                            <S.NewToTripshock>New to TripShock</S.NewToTripshock>
                        )} */}
                    </S.ImageContainer>
                    <S.ContentContainer>
                        <S.Title>{name}</S.Title>
                        <S.DurationWrapper>
                            {!!reviews_cnt || !!reviews || !!rate_count ? (
                                <>
                                    <Rating rating={rating_stars} />
                                    {reviewsCountWithComma !== '0' && (
                                        <S.RatingText>({reviewsCountWithComma})</S.RatingText>
                                    )}
                                </>
                            ) : (
                                <S.NoReviews>Newly added</S.NoReviews>
                            )}
                            {reviews_cnt === '0' && <S.NoReviews>Newly added</S.NoReviews>}

                            <S.Time>
                                {duration !== '' && (
                                    <>
                                        <S.ClockIcon />
                                        <S.Minutes>{duration}</S.Minutes>
                                    </>
                                )}
                            </S.Time>
                        </S.DurationWrapper>
                    </S.ContentContainer>
                </S.PreviewWrapper>
            </S.CardWidth>
        </Link>
    );
};

export const FeaturedActivitiesPreview = memo(FeaturedActivitiesPreviewsContainer);
