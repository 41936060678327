import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';

export const PreviewWrapper = styled.div`
    position: relative;
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_card_default};
    display: grid;
    height: 310px;
    min-width: 316px;
    overflow-x: hidden;
    overflow-y: hidden;
    cursor: pointer;
    width: 316px;

    @keyframes loading {
        0% {
            background-color: ${cssVars.bg_white_dark3};
        }
        100% {
            background-color: ${cssVars.bg_ash_blueDark};
        }
    }

    animation: loading 1.2s linear infinite alternate;

    ${Screen.ipad} {
        height: 336px;
        min-width: auto;
        width: 340px;
    }
    ${Screen.desktop} {
        height: 274px;
        width: 248px;
    }
`;

export const ImageContainer = styled.div`
    position: relative;
    padding: 12px 12px 0;
    height: 199px;

    ${Screen.ipad} {
        height: 223px;
    }
    ${Screen.desktop} {
        height: 159px;
    }
`;

export const ImgWrap = styled.div`
    position: relative;
    border-radius: ${radius.small};
    overflow: hidden;
    background-color: ${cssVars.bg_ash_blueDark};
    width: 100%;
    height: 100%;
`;

export const Price = styled.div`
    background-color: ${cssVars.bg_white_dark3};
    height: 28px;
    min-width: 52px;
    border-radius: ${radius.large};
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 4px 14px;

    ${Screen.ipad} {
        margin-right: 20px;
    }
    ${Screen.desktop} {
        height: 24px;
    }
`;

export const Title = styled.div`
    background-color: ${cssVars.bg_ash_blueDark};
    width: 100%;
    height: 40px;
    margin-top: 16px;
    border-radius: ${radius.large};
`;

export const BottoLine = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    height: 20px;

    ${Screen.ipad} {
        margin-top: 24px;
    }

    *:first-child {
        width: 50%;
    }
`;

export const Text = styled.div`
    background-color: ${cssVars.bg_ash_blueDark};
    width: 30%;
    height: 20px;
    border-radius: ${radius.large};
`;
