import { radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';

export const ImgWrap = styled.div`
    height: 238px;
    position: relative;
    display: flex;
    border-radius: ${radius.small};
    overflow: hidden;

    ${Screen.ipad} {
        height: 324px;
    }

    ${Screen.desktop} {
        height: 300px;
        max-width: 350px;
    }
`;

export const Gradient = styled.div`
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
        180deg,
        rgba(12, 11, 11, 0) 31.6%,
        rgba(0, 0, 0, 0.416) 73.45%
    );
`;

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
    height: auto;

    ${Screen.desktop} {
        height: 300px;
        max-width: 350px;
    }
`;

export const Title = styled.span<{ pathName: string }>`
    color: ${Palette.black.normal};
    width: 85%;
    margin: 15px 10px 0px;
    position: absolute;
    left: 0px;
    bottom: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${Text.Mobile.large.semibold};

    ${Screen.ipad} {
        ${Text.Full.medium.semibold};
    }

    ${Screen.desktop} {
        color: ${(props) =>
            props.pathName !== '/' ? `${Palette.white.normal}` : `${Palette.black.normal}`};
        width: 224px;
        margin: 16px 10px 18px;
        left: 10px;
        bottom: 50px;
    }
`;
