import { trackViewPromotion } from '.';
import { IItem } from './interfaces';

const DELAY = 1500;

class PromotionInterceptor {
    _items: IItem[] = [];
    _viewed: number[] = [];
    _listName = '';
    _updatingStarted = false;

    addPromotion(activity: IItem, promotion_name?: string) {
        const { item_list_name, index } = activity;

        if (this._listName !== item_list_name) {
            this._listName = item_list_name || '';
            this._viewed = [];
        }

        if (!this._viewed.includes(index)) {
            this._viewed.push(index);
            this._items.push(activity);
        }

        if (!this._updatingStarted) {
            this._updatingStarted = true;

            setTimeout(() => {
                if (this._items.length) {
                    trackViewPromotion({
                        items: this._items,
                        promotion_name,
                    });
                    this._items = [];
                }

                this._updatingStarted = false;
            }, DELAY);
        }
    }
}

export const promotionInterceptor = new PromotionInterceptor();
