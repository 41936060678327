import { ReactElement } from 'react';
import * as S from './FeaturedActivitiesSkeleton.styles';

export const FeaturedActivitiesSkeleton = (): ReactElement => {
    return (
        <S.PreviewWrapper>
            <S.ImageContainer>
                <S.ImgWrap />
                <S.Price />
                <S.Title />
                <S.BottoLine>
                    <S.Text />
                    <S.Text />
                </S.BottoLine>
            </S.ImageContainer>
        </S.PreviewWrapper>
    );
};
